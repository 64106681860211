import React from "react"

const PlainMark = ({ className = "" }) => (
  <svg
    className={`fill-current ${className}`}
    width="100%"
    height="100%"
    viewBox="0 0 500 500"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1.45297,0.461061,-0.461061,1.45297,-474.892,-700.352)">
      <path d="M619.747,592.418L594.111,600.993L577.206,578.143L600.1,578.143L619.747,592.418ZM707.42,578.143L691.721,600.149L664.613,591.6L683.135,578.143L707.42,578.143ZM532.923,529.337L540.428,552.434L514.647,544.303L514.402,515.88L532.923,529.337ZM770.192,542.093L743.236,551.11L750.311,529.337L769.959,515.062L770.192,542.093ZM507.264,450.366L499.76,473.463L483.682,451.731L500.19,428.592L507.264,450.366ZM799.553,449L783.045,472.139L775.97,450.366L783.475,427.269L799.553,449ZM532.923,371.395L513.276,385.67L513.042,358.638L539.998,349.622L532.923,371.395ZM768.587,356.429L768.833,384.852L750.311,371.395L742.806,348.298L768.587,356.429ZM706.029,322.589L683.135,322.589L663.487,308.314L689.123,299.738L706.029,322.589ZM618.622,309.132L600.1,322.589L575.814,322.589L591.514,300.582L618.622,309.132Z" />
    </g>
    <g transform="matrix(3.15302,0.994423,-0.994423,3.15302,-639.413,-1884.6)">
      <path d="M450.666,466.82L468.54,479.806L490.633,479.806L497.461,500.818L515.335,513.804L508.507,534.816L515.335,555.828L497.461,568.814L490.633,589.826L468.54,589.826L450.666,602.813L432.793,589.826L410.699,589.826L403.872,568.814L385.998,555.828L392.825,534.816L385.998,513.804L403.872,500.818L410.699,479.806L432.793,479.806L450.666,466.82Z" />
    </g>
  </svg>
)

export default PlainMark
